<template>
<div>
    
    <v-card elevation="0" outlined style="border:2px solid rgb(251, 192, 45);">
        
      <v-col cols="12">
        <v-card-title>
          <v-icon class="mr-2">mdi-account-plus</v-icon> 
          S'inscrire
        </v-card-title> 
        <v-card-subtitle>Inscription gratuite</v-card-subtitle>

        <v-form @submit.prevent="register">

          <v-text-field outlined v-model="name" id="name" label="Nom d'utilisateur" 
                        :rules="[rules.required, rules.minName, rules.maxName]"
                        :error="errorRegister == 'USERNAME_EXISTS'"></v-text-field>

          <v-text-field outlined v-model="email" id="email" label="E-mail" 
                        :rules="[rules.required]"
                        :error="errorRegister == 'EMAIL_EXISTS'"></v-text-field>

          <v-text-field outlined v-model="password" id="password" label="Mot de passe" 
                        :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpwd ? 'text' : 'password'"
                        hint="Au moins 8 caractères"
                        counter
                        @click:append="showpwd = !showpwd"
                        @keyup.enter.exact="showDialogCaptcha()"
          ></v-text-field>

          <v-divider/>

          <v-text-field outlined v-model="recruitCode" id="name" label="Code de parrainage"  class="mt-5 font-led" style="font-size:1.3em;"
                        :rules="[rules.code]" :hide-details="!rules.code" counter append-icon="mdi-pirate"
                        :error="errorRegister == 'CODE_NOT_EXISTS'" color="green"></v-text-field>

          <small class="primary--text d-block text-center">
            <b>En utilisant un code de parrainage,<br>vous gagnez 1 PRT de +
            dès votre inscription...</b><br><br>

            Pour trouver un code de parrainage,<br>
            recherchez les pirates autour de vous !<br>
            <b>Bonne chance !</b>
          </small>

          <v-alert v-if="errorRegister != false" 
                   dense dark color="red" class="mt-3 mb-0 text-center">
            <v-icon small>mdi-alert</v-icon>
            <template v-if="errorRegister == 'ACCOUNT_DELETED'">Cette adresse e-mail a déjà été utilisée</template>
            <template v-if="errorRegister == 'EMAIL_EXISTS'">Cette adresse e-mail est déjà utilisée</template>
            <template v-if="errorRegister == 'USERNAME_EXISTS'">Ce nom d'utilisateur est déjà utilisé</template>
            <template v-if="errorRegister == 'CAPTCHA_FAILED'">Captcha invalide</template>
          </v-alert>

        </v-form>
      </v-col>
          
      <v-card-actions>
        <v-spacer/>
          <v-btn class="secondary--text" :loading="$store.state.auth.register.loading" 
                 color="primary" @click="showDialogCaptcha()" block elevation="0">
            <v-icon>mdi-account-plus</v-icon> Créer mon compte
          </v-btn>
      </v-card-actions>
    </v-card>

    <div class="pa-3 mt-2 text-center">
      <!-- <v-checkbox dense v-model="chk" class="d-inline-block ml-2" color="indigo" style="margin-right:-10px;"></v-checkbox>  -->
      En créant votre compte,<br>vous acceptez les 
      <router-link to="/cgu" target="_blank">CGU</router-link> et <router-link to="/cgv" target="_blank">CGV</router-link>
    </div>

    <DialogCaptcha :actionSuccess="register"/>

      
</div>
</template>

<script>

import router from '../../router/router'
import axios from 'axios'

import DialogCaptcha from '@/components/main-layout/DialogCaptcha.vue'

//const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'register',
  components: {
    DialogCaptcha
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    showpwd: false,
    errorRegister: false,
    chk: true,
    showDialogBeta: false,
    recruitCode: "",
    rules: {
          required: value => !!value || 'Champs obligatoire',
          min: v => v.length >= 8 || 'Min 8 caractères',
          minName: v => v.length >= 3 || 'Min 3 caractères',
          maxName: v => v.length <= 15 || 'Max 15 caractères',
          code: v => v.length <= 8 || 'Le code doit contenir exactement 8 caractères',
        },
  }),
  mounted: function(){
    this.recruitCode = this.$route.params.recruitCode != null ? this.$route.params.recruitCode : ""
  },
  methods: {
    showDialogCaptcha(){
      //if(this.$refs.formContact.validate())
      this.$store.dispatch('captcha/setShowDialog', true)
    },
    async register(answerCaptcha){
      //console.log("try to register", this.name, this.email, this.password)
      //if(!this.chk) return 

      const res = await axios.post('/auth/register', 
                                    { name: this.name,
                                      email: this.email,
                                      password: this.password,
                                      recruitCode: this.recruitCode,
                                      captcha: {
                                        answer: answerCaptcha,
                                        sessionid: this.$store.state.captcha.session.sessionid
                                      }
                                    })
      console.log("res /auth/register", res)
      if(res.data.error == false){
        console.log("USER SAVED WITH SUCCES", res.data)
        this.errorRegister = false
        this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
        router.push('/confirm-register');
      }else{
        this.errorRegister = res.data.msg
      }
    }
  }
}
</script>
